import React from 'react';
import { navigate } from 'gatsby';

import { loginAs } from '../../actions';
import { isBrowser, devLog } from '../../utils';
import BaseHOC from '../../components/BaseHOC';

const LoginAs = ({ dispatch }) => {
  if (!isBrowser) return null;

  const urlParams = new URLSearchParams(window.location.search);
  const urlTokenPair = urlParams.get('loginAsTokenPair');

  if (urlTokenPair) {
    devLog('Token found.');
    dispatch(loginAs(urlTokenPair));
  } else {
    devLog('Token not found.');
    navigate('/app/login/');
  }
  return <></>;
};

export default BaseHOC(LoginAs);
